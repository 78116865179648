<template>
</template>

<script>
    export default {
        name: "CreateEdit"
    }
</script>

<style scoped>

</style>